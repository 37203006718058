.admin-game {
  margin: 20px 0;
}

.admin-game > h1 {
  margin: 10px 0;
}

.admin-game .answer-button {
  padding: 20px;
  font-weight: 900;
  font-size: 2em;
}

.admin-game .answer-button.wrong {
  background: red;
  color: white;
}

.admin-game .answer-buttons {
  display: flex;
  justify-content: space-evenly;
  margin: 20px 0;
}

.admin-game .admin-game--curent-team-box {
  display: flex;
  justify-content: space-evenly;
  margin: 30px 0;
}

.admin-game .admin-game--curent-team-box button {
  position: relative;
  padding: 30px 40px;
}

.admin-game .admin-game--curent-team-box button.active::before {
  position: absolute;
  top: -40px;
  left: 0px;
  content: 'TERAZ ODPOWIADA'
}

.next-question-cointainer {
  width: 100%;
  text-align: center;
}

.next-question-button {
  padding: 10px;
}
