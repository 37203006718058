@font-face {
  font-family: 'Familiada Regular';
  src: url(/static/media/familiada.401a7905.ttf) format('truetype'),
    url(/static/media/familiada.bed6fdce.woff) format('woff'),
    url(/static/media/familiada.33b1f61c.svg) format('svg'),
    url(/static/media/familiada.2e6dc538.eot) format('eot');
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #000;
  color: #00A4E3;
}

body {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  min-height: 100vh;
  width: 100vw;
  font-family: 'Familiada Regular';
  text-transform: uppercase;
  font-size: 10px;
}
.btn {
  border: 1px dotted yellow;
  font-size: 2em;
  font-family: 'Familiada Regular';
  padding: 2vh 4vw;
  margin: 2vw;
}
input {
  border: 1px dotted yellow;
  colort: yellow;
  padding: 5px;
  font-size: 0.7em;
}
@media (orientation: landscape) and (min-width: 1024px) {
  .btn {
    border-width: 3px;
    font-size: 4em;
  }
  input {
    border-width: 3px;
    padding: 10px;
  }
}
@media (orientation: landscape) and (min-width: 1366px) {
  .btn {
    border-width: 4px;
    font-size: 5em;
  }
  input {
    border-width: 4px;
    padding: 10px;
  }
}

.label {
  height: 60px;
  line-height: 60px;
  font-size: 2em;
}

.input {
  font-size: 2em;
}

.inline-label {
  font-size: 2em;
  margin-right: 20px;
}

.current-team {
  height: 5%;
}

.sideBoard, .fails, .poinst{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-content: center;
            align-content: center;
    text-align: center;
}

.sideBoard{
    width: 10%;
    height: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.fail{
    padding: 3vh 0;
}
.fails{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 80%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 3em;
}
.points{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 2vw;
    min-height: 20vh;
    width: 100%;
}
.teamName {
    font-size: 3em;
    z-index: 9999;

}
.points-value {
    font-size: 4em;
}

@media(orientation:landscape) and (min-width: 1024px){

    .fails{
      font-size: 5em;
    }
    .points{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: space-evenly;
                justify-content: space-evenly;

        padding: 2vw;
        min-height: 20vh;
        width: 100%;
    }
}


.mainBoard
{
    width: 67%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.startingPage{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    width: 100%;
    height: 100%;
}
.familiada{
    font-size: 3em;
}
.startingPage label{
    font-size: 1.5em;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}
@media(orientation: landscape) and (min-width: 1024px ){
    .familiada{
        font-size: 7em;
    }
    .startingPage label{
        font-size: 2.5em;
    }
}
@media(orientation: landscape) and (min-width: 1366px ){
    .familiada{
        font-size: 9em;
    }
    .startingPage label{
        font-size: 3em;
    }
}

.questionBox{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    height: 100%;
    width: 100%;
}
.winner{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content:center;
            justify-content:center;
    font-size: 3em;
}
.question{
    font-size: 2em;
    line-height: 2.2em;
    padding: 4vw 0;
    width:80%;
}
.answers{
    width: 80%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}
.answerBox{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 2vh;
    font-size: 1.5em;
    line-height: 1.6em;
}
.sum {
    font-size: 2.8em;
    width: 100%;
    text-align: right;
    padding: 2vh;
    margin-top: 20px;
}
.sum--label {
    margin-right: 20px;
}
.answerForm{
    visibility: hidden;
    justify-self: flex-end;
    position: relative;
}
.answerForm label{
    font-size: 1.3em;
    padding: 2vw;
}
.answerForm input{
    margin: 10px 5px;
}
.answerFormBtn{
    border: 1px dotted yellow;
    font-family: 'Familiada Regular';
    padding: 2vh 2vw;
    margin: 2vw;
}
.activeTeam{
    visibility: hidden;
    font-size: 1.3em;
}
@media(orientation: landscape){
    .winner{
        font-size: 2em;
    }
    .question{
        font-size: 1.5em;
        line-height: 1.6em;
    }
    .answerBox{
        padding: 2vh;
        font-size: 1.2em;
        line-height: 1.3em;
    }
    .answerForm{
        justify-self: flex-end;
        -webkit-align-self: center;
                align-self: center;
        width: 80%;
    }
    .answerForm label{
        font-size: 1.1em;
        padding: 2vw;
    }
    .answerForm input{
        padding: 2px;
    }
    .formBtn{
        padding: 2vh 2vw;
        margin: 2vh;
    }
}
@media(orientation: landscape) and (min-width: 731px){
    .winner{
        font-size: 3em;
    }
    .question{
        font-size: 2em;
        line-height: 2.1em;
    }
    .answerBox{
        padding: 2vh;
        font-size: 1.5em;
        line-height: 1.6em;
    }
    .answerForm label{
        font-size: 1.3em;
        padding: 2vw;
    }
    .answerForm input{
        padding: 5px;
    }
    .formBtn{
        padding: 2vh 2vw;
        margin: 2vh;
    }
}
@media(orientation: landscape) and (min-width: 1024px){
    .winner{
        font-size: 5em;
    }
    .question{
        font-size: 3.5em;
    }
    .answerBox{
        font-size: 2.8em;
    }
    .answerForm label{
        font-size:2em;
    }
    .answerForm input{
        padding: 5px;
        font-size: 1.5em;
    }
    .formBtn{
        font-size: 1.5em;
    }
}
@media(orientation: landscape) and (min-width: 1366px){
    .activeTeam{
        font-size: 2em;
        /*position: absolute;*/
        /*top: 0;*/
        /*left: 0;*/
        /*margin-left: 3%;*/
    }
}

.app{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    max-width: 1440px;
}
.turnHorizon{
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-family: 'Montserat', sans-serif;
    font-size: 2em;
}
.turnHorizon h2{
    width: 80%;
    text-align: center;
}
@media(orientation: landscape){
    .turnHorizon{
       display: none;
    }
}
.whosFirst{
    display: -webkit-flex;
    display: flex;
    z-index: 9;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0%;
    left: 0;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: #000;
    -webkit-flex-direction: column;
            flex-direction: column;

}
.btns{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}
.whosFirst button{
    padding: 10vh 2vw;
    font-size: 1.5em;
}
.whosFirst .question{
    text-align: center;
}
@media(orientation: landscape) and (min-width: 1024px)
{
    .whosFirst button{
        font-size: 2em;
    }
}
@media(orientation: landscape) and (min-width: 1366px)
{
    .whosFirst button{
        font-size: 2.5em;
    }
}
.questions-list-component {
    width: 80vw;
    height: 50vh;
    margin-top: 20px;
    font-size: 2em;
    overflow: auto;
}

.questions-list--item {
    display: grid;
    grid-template-columns: 20fr 1fr 1fr;
    grid-column-gap: 20px;
    margin: 20px 0;
}

.question-item--answer {
    padding: 10px;
    font-size: 0.7em;
}

.admin-game-view {
    overflow: auto;
}

.admin-header {
}

.admin-header--title {
    display: inline;
    margin-right: 20px;
}

.admin-header--refresh-button {
    padding: 10px;
}

.admin-teams-names {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-row-gap: 20px;
    width: 80vw;
    margin: 20px 0;
}

.new-question-link {
    margin: 20px 0;
    font-size: 2em;
    display: block;
}

.admin-game-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
}

.new-game-start-button {
    padding: 10px;
    font-size: 2em;
}

.spacer {
    margin: 20px 0;
}

.admin-game {
  margin: 20px 0;
}

.admin-game > h1 {
  margin: 10px 0;
}

.admin-game .answer-button {
  padding: 20px;
  font-weight: 900;
  font-size: 2em;
}

.admin-game .answer-button.wrong {
  background: red;
  color: white;
}

.admin-game .answer-buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  margin: 20px 0;
}

.admin-game .admin-game--curent-team-box {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  margin: 30px 0;
}

.admin-game .admin-game--curent-team-box button {
  position: relative;
  padding: 30px 40px;
}

.admin-game .admin-game--curent-team-box button.active::before {
  position: absolute;
  top: -40px;
  left: 0px;
  content: 'TERAZ ODPOWIADA'
}

.next-question-cointainer {
  width: 100%;
  text-align: center;
}

.next-question-button {
  padding: 10px;
}

.question-box {
    width: 80vw;
    display: grid;
    grid-template-columns: 0.2fr 1fr;

    grid-row-gap: 20px;
}

.question-label {
    /*grid-area: question-label;*/

}

.question-input {
    /*grid-area: question-input;*/

}


.answer-input {
    /*grid-area: question-input;*/
}

.question-input {
    /*width: 400px;*/
}

.question-box input {
    outline: none;
}

.question-box .save {
    grid-column: 1/3;
    grid-row: 8/11;
}

.answer-box {
    display: grid;
    grid-template-columns: 8fr 1fr 1fr;
    grid-column-gap: 10px;

}

.answer-points-label {
    text-align: center;
}


