.admin-game-view {
    overflow: auto;
}

.admin-header {
}

.admin-header--title {
    display: inline;
    margin-right: 20px;
}

.admin-header--refresh-button {
    padding: 10px;
}

.admin-teams-names {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-row-gap: 20px;
    width: 80vw;
    margin: 20px 0;
}

.new-question-link {
    margin: 20px 0;
    font-size: 2em;
    display: block;
}

.admin-game-buttons {
    display: flex;
    justify-content: space-evenly;
}

.new-game-start-button {
    padding: 10px;
    font-size: 2em;
}

.spacer {
    margin: 20px 0;
}
