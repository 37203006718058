.question-box {
    width: 80vw;
    display: grid;
    grid-template-columns: 0.2fr 1fr;

    grid-row-gap: 20px;
}

.question-label {
    /*grid-area: question-label;*/

}

.question-input {
    /*grid-area: question-input;*/

}


.answer-input {
    /*grid-area: question-input;*/
}

.question-input {
    /*width: 400px;*/
}

.question-box input {
    outline: none;
}

.question-box .save {
    grid-column: 1/3;
    grid-row: 8/11;
}

.answer-box {
    display: grid;
    grid-template-columns: 8fr 1fr 1fr;
    grid-column-gap: 10px;

}

.answer-points-label {
    text-align: center;
}

