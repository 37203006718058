.questions-list-component {
    width: 80vw;
    height: 50vh;
    margin-top: 20px;
    font-size: 2em;
    overflow: auto;
}

.questions-list--item {
    display: grid;
    grid-template-columns: 20fr 1fr 1fr;
    grid-column-gap: 20px;
    margin: 20px 0;
}

.question-item--answer {
    padding: 10px;
    font-size: 0.7em;
}
