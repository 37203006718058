.app{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    max-width: 1440px;
}
.turnHorizon{
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserat', sans-serif;
    font-size: 2em;
}
.turnHorizon h2{
    width: 80%;
    text-align: center;
}
@media(orientation: landscape){
    .turnHorizon{
       display: none;
    }
}